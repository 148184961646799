import { useContext, useState, useEffect, useRef} from 'react';
import { Link } from "react-router-dom";
import { useMutation, useLazyQuery } from '@apollo/client';
import LazyLoad from 'react-lazyload';
import json2mq from 'json2mq';
//Material-UI
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
//User Defined Components
import { AuthContext } from '../context/auth';
import { ResultsContext } from '../context/result';
import { SessionContext } from '../context/session';
import { useForm } from '../hooks/form';
import { CssTextField } from '../styles/LoginStyles';
import CompanyDetailsModal from '../components/loginaccess/CompanyDetailsModal';
//QUERIES
import { LOGIN_USER, FORGOT_PASSWORD } from '../util/graphql';
//Custom defined
import {emailValidate} from '../util/validators';
import passwordValidate from '../util/validators';
import PreLoader from '../components/PreLoader';
import logoOutline from "../assets/imgaes/rhr-outline.svg";
import logo from "../assets/imgaes/rhrLogo.svg";
import SnackBarAlert  from '../components/SnackBarAlert';
// import { GoogleAuth } from "./GoogleAuth";

// Google Authentication
import jwt_decode from "jwt-decode";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  });

function Login(props) {
    const context = useContext(AuthContext);
    const resultsContext = useContext(ResultsContext);
    const sessionContext = useContext(SessionContext);
    const [company, setCompany] = useState("");
    const [gotoJourney, setGotoJourney] = useState(false);
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('error');

    const googleButton = useRef(null);

    

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = "748156039645-uam3bkec014veb8tuh2com3q95quidht.apps.googleusercontent.com"
    
        loadScript(src)
          .then(() => {
            /*global google*/
            console.log("google :::: ")
            google.accounts.id.initialize({
              client_id: id,
              callback: handleCredentialResponse,
            })
            google.accounts.id.renderButton(
              googleButton.current, 
              { theme: 'outline', size: 'large' } 
            )
          })
          .catch(console.error);
    
        return () => {
          const scriptTag = document.querySelector(`script[src="${src}"]`)
        //   if (scriptTag) document.body.removeChild(scriptTag)
        }
      }, [handleCredentialResponse]);

      function handleCredentialResponse(response){
        var userObject = jwt_decode(response.credential);
        console.log("User Email : ", userObject.email);
        loginUser({variables: {
            email: userObject.email,
            password: ''
        }});
    }
    
    //Snackbar Alert State
    const [openSnackBar,setOpenSnackBar] = useState(true);
    
    const mobileDevice = useMediaQuery(
        json2mq({
          maxWidth: 767,
        }),
      );
  
    const { onChange, onSubmit, values } = useForm(loginUserCallback, {
        email: '',
        password: ''
    });

    useEffect(() => {
        if (context.user) {
            if (context.user.isCoach || context.user.isAdmin) {
                props.history.push('/dashboard/clients');
            } else if ((context.user.companyId.name !== "None") || gotoJourney) {
                props.history.push('/dashboard');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.user, gotoJourney])

    const [forgotPassword, { data }] = useLazyQuery(FORGOT_PASSWORD);

    useEffect(() => {
        if (data) {
            if (data.forgotPassword) {
                setAlert(true);   
                setSeverity('success');
                setMsg("Email sent successfully!");
                setTimeout(() => {setAlert(false)}, 5000);
            } else {
                setAlert(true);   
                setSeverity('error');
                setMsg("This email is not registered yet! Please contact your coach.");
                setTimeout(() => {setAlert(false)}, 3000);
            }
        }
    }, [data]);
    
    const [loginUser, { loading }] = useMutation(LOGIN_USER, {
        variables: values,
        update(_, { data: { clientLogin: userData } }) {
            //Login user
            context.login(userData);

            if(!(userData.isAdmin || userData.isCoach)){
            //CREATE SESSION & RESULTS CONTEXT for SELF-AUDIT management and REPORTS
            const userSession = userData.currentSessionId;
            const userResults = userData.currentResultId;
            const resultsData = {
                c1: { q1: userResults.c1.q1, q2: userResults.c1.q2, q3: userResults.c1.q3, q4: userResults.c1.q4, q5: userResults.c1.q5, average: userResults.c1.average, importance: userResults.c1.importance, does: userResults.c1.does, should: userResults.c1.should },
                c2: { q1: userResults.c2.q1, q2: userResults.c2.q2, q3: userResults.c2.q3, q4: userResults.c2.q4, q5: userResults.c2.q5, average: userResults.c2.average, importance: userResults.c2.importance, does: userResults.c2.does, should: userResults.c2.should },
                c3: { q1: userResults.c3.q1, q2: userResults.c3.q2, q3: userResults.c3.q3, q4: userResults.c3.q4, q5: userResults.c3.q5, average: userResults.c3.average, importance: userResults.c3.importance, does: userResults.c3.does, should: userResults.c3.should },
                c4: { q1: userResults.c4.q1, q2: userResults.c4.q2, q3: userResults.c4.q3, q4: userResults.c4.q4, q5: userResults.c4.q5, average: userResults.c4.average, importance: userResults.c4.importance, does: userResults.c4.does, should: userResults.c4.should },
                c5: { q1: userResults.c5.q1, q2: userResults.c5.q2, q3: userResults.c5.q3, q4: userResults.c5.q4, q5: userResults.c5.q5, average: userResults.c5.average, importance: userResults.c5.importance, does: userResults.c5.does, should: userResults.c5.should },
                c6: { q1: userResults.c6.q1, q2: userResults.c6.q2, q3: userResults.c6.q3, q4: userResults.c6.q4, q5: userResults.c6.q5, average: userResults.c6.average, importance: userResults.c6.importance, does: userResults.c6.does, should: userResults.c6.should },
                c7: { q1: userResults.c7.q1, q2: userResults.c7.q2, q3: userResults.c7.q3, q4: userResults.c7.q4, q5: userResults.c7.q5, average: userResults.c7.average, importance: userResults.c7.importance, does: userResults.c7.does, should: userResults.c7.should },
            };
        
            // INITIALIZE SESSION & RESULTS
            if (userSession.status === "INVITED") {
                sessionContext.initiate({...userSession});
            } else {
                sessionContext.initiate(userSession);
            }
            resultsContext.create(resultsData);
            
            if (userData.companyId.name !== "None") {
                
            } else {
                setCompany(userData.companyId.name);
            }
        }
        },
        onError(err) {
            setAlert(true);   
            setSeverity('error');
            // setMsg("Wrong credentials!");    Old messages, Problem : Generic message (not from API)
            setMsg(err.graphQLErrors[0].extensions.errors.general);
            setTimeout(() => {setAlert(false)}, 3000);
        }
    });

    function loginUserCallback() {
        // Email validation
        let tmp = values.email.trim();
        if (tmp === '' || !emailValidate(values.email)) {
            setAlert(true);   
            setSeverity('error');
            setMsg('Please enter a valid email address!');
            setTimeout(() =>  {setAlert(false)}, 3000);
            return false;
        } else {
            // Password validation
            tmp = values.password.trim();
            if (tmp === '' || !passwordValidate(values.password)) {
                setAlert(true); 
                setSeverity('error');  
                setMsg("Please enter a valid password with at least 8 characters!");
                setTimeout(() => {setAlert(false)}, 5000);
                return false;
            }
        }
        loginUser();
    }

    function forgotPasswordCallback() {
        // Email validation
        let tmp = values.email.trim();
        if (tmp === '' || !emailValidate(values.email)) {
            setAlert(true); 
            setSeverity('error');  
            setMsg('Please enter your registered email address!');
            setTimeout(() =>  {setAlert(false)}, 3000);
            return false;
        }
        forgotPassword({ variables: { email: values.email } });
    }

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    }

    const handleLogout = () => {
        values.email = "";
        values.password = "";
        context.logout();
    }

    if (loading) return <PreLoader/>;

    return (
        <>
        {
        alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
        }
        
        <Paper elevation={0} className="rhr-login-screen min-h-screen md:pb-40 relative flex flex-col">
            <LazyLoad height={200} className='lg:auto max-w-full fixed right-0 md:left-auto left-16 md:right-16 bottom-0 md:block hidden'>
                <img className=' lg:auto max-w-full float-right' src={`${logoOutline}`} alt="" width='535' />
            </LazyLoad>
            
            <div className="rhr-login-header md:mb-14 mt-0 lg:py-10 lg:pl-4 py-4">
                <Link to={'/'}>
                    <img className='d-inline-flex pl-8 box-content' src={logo} alt="" width='57' />
                </Link>
            </div>
            <div className="form_container_wrapper flex items-center justify-center relative">
                {
                    (context.user === null) ? <>
                        <div className="form-container md:bg-alabaster pt-8 md:pt-12 pb-8 md:px-28 px-9  w-full" style={{maxWidth:'632px'}}>
                            <div className="form-container-top mb-7 text-dovegray">
                                <h3 className='gesta-bold md:text-4xl md:mb-4' style={{fontSize:'32px'}}>
                                    Founder's Journey<sup className="text-base">SM</sup>
                                </h3>
                                <p className='text-lg'>
                                    Please enter your login details below.
                                </p>
                            </div>
                            <form
                            className="w-full"
                                noValidate onSubmit={onSubmit}>
                                <div className="w-full">
                                    <CssTextField
                                        id="email"
                                        type="email"
                                        name="email"
                                        label="Email"
                                        defaultValue={values.email}
                                        onChange={onChange}
                                        fullWidth={true}
                                        size={mobileDevice ? 'small': 'medium'}
                                    />
                                </div>
                                <div className="w-full mt-4">
                                    <CssTextField
                                        id="password"
                                        type="password"
                                        name="password"
                                        label="Password"
                                        defaultValue={values.password}
                                        onChange={onChange}
                                        fullWidth={true}
                                        size={mobileDevice ? 'small': 'medium'}
                                    />
                                </div>
                                <Button
                                    className='capitalize w-full mt-12 rounded-none text-sm md:gesta-regular text-white text-center py-2.5 bg-bigstone text-lg'
                                    variant="contained"
                                    color="primary"
                                    type="submit" >
                                    LOGIN
                                </Button>
                                <div className='text-center mt-6 lg:pb-4 block items-center justify-between'>
                                    <span  onClick={forgotPasswordCallback} className='text-lg font-medium text-black cursor-pointer'>Forgot Password?</span>

                                    <p style={{letterSpacing: `${mobileDevice ? '0' : '-0.2px'}`}} className="gesta-medium mt-3 text-bigstone text-sm md:text-base">RHR employees can use the "Sign in with Google" button for login</p>

                                    <div className='googleButton' ref={googleButton}></div>
                                </div>
                            </form>                            
                        </div>
                    </> : <CompanyDetailsModal setCo={setCompany} setJourney={setGotoJourney} logout={handleLogout} />
                }
            </div>
            
        </Paper>
        </>
    );
}

export default Login;
